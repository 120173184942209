<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      :options="toolbarOptions"
      @click-option="clickToolbarOption"
    />
    <b10-page-content>
      <b10-view-summary
        :item="formattedItem"
        :showing.sync="showingDialogs.moreInfo"
        :loading-data="loadingData"
        :show-alerts="!routeFromBrowserHistory"
      >
        <template slot="extraSubtitle">
          <extra-subtitle
            :ffijada="formattedItem.ffijada"
            :avisar-antes-ir="formattedItem.avisar_antes_ir"
            :mano-de-obra-facturable="formattedItem.manodeobrafacturable"
            :idtfacturacion-kilometros="formattedItem.idtfacturacion_kilometros"
            :cant-acciones-realizadas="formattedItem.cant_acciones_realizadas"
            :cant-acciones-total="formattedItem.cant_acciones_pendientes + formattedItem.cant_acciones_realizadas"
            :tmotivo-bloqueo-descripcion="formattedItem.tmotivo_bloqueo_ot_descripcion"
            :pendiente-devolucion="formattedItem.pendiente_devolucion"
            :tecnico-previsto="formattedItem.tecnico_previsto_nombre"
            :material-pendiente="formattedItem.materialPendiente"
          />
        </template>
      </b10-view-summary>
      <b10-view-details
        :details="details"
        @click-detail="clickDetail"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { viewPageMixin } from '@/mixins/viewPageMixin'
import Data from './OrdenTrabajoViewData'
import ExtraSubtitle from './components/ExtraSubtitle'
import { TABLA, ORDEN_TRABAJO, CHECKLIST, LORDEN_TRABAJO } from '@/utils/consts'
import { get } from 'vuex-pathify'
import { downloadParteTrabajo } from '@/sync/download'
import  filters from '@/utils/filters'
import { captureGeolocation, getLatLongURL } from '@/utils/maps'

export default {
  components: {
    ExtraSubtitle
  },
  mixins: [viewPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      moreInfoRows: [
        'orden_trabajo_serie_numero',
        'desc_estado_ot',
        'tactuacion_descripcion',
        'tfacturacion_kilometros_desc',
        { name: 'manodeobrafacturable', filter: this.$options.filters.humanizeBoolean },
        'tecnico_previsto_nombre',
        'idcliente',
        'cliente_nombre',
        'sistema_codigo',
        'sistema_descripcion',
        'sistema_direccion',
        'sistema_nabonado1',
        'tsistema_descripcion',
        'forma_pago_descripcion',
        'prioridad_descripcion',
        'solicitante',
        'responsable_propiedad',
        { name: 'avisar_antes_ir', filter: this.$options.filters.humanizeBoolean },
        { name: 'ffijada', filter: this.$options.filters.shortDateTime },
        { name: 'ffinalizacion', filter: this.$options.filters.shortDate },
        { name: 'forden', filter: this.$options.filters.shortDate },
        { name: 'fprevista', filter: this.$options.filters.shortDate },
        'agente_nombre',
        'comercial_nombre',
        'npedido_cliente',
        'notas_internas',
        'observacion',
        {
          name: 'pendiente_devolucion',
          label:'Pendiente de devolución',
          filter: this.$options.filters.humanizeBoolean,
        },
        'grupo_usuario_desc',
      ],
      toolbarOptions: {
        irSistema: {
          title: 'Ir al sistema',
          visible: true,
        },
        asignarAMi: {
          title: 'Asignar a mí',
          visible: true,
          icon: 'asignado'
        },
        asignarAPrevisto: {
          title: '',
          visible: false,
          icon: 'asignado'
        },
        asignarAOtros: {
          title: 'Asignar a otros',
          visible: true,
          icon: 'asignado'
        },
        irParte: {
          title: 'Ir al parte',
          visible: true,
        },
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        geolocalizar: {
          title: 'Capturar geolocalización actual',
          visible: true,
          icon: 'mapLocation',
        },
      },
      showingDialogs: {
        moreInfo: false,
      },
      CHECKLIST,
      parteTrabajoOffline: {},
    }
  },
  computed: {
    formattedItem () {
      if (!_.isEmpty(this.item.dataset)) {
        const item = _.cloneDeep(this.item.dataset)
        item.id = item.idorden_trabajo
        item.title = this.$online.ordenTrabajo.title(item)
        item.subtitle = this.$online.ordenTrabajo.subtitle(item)
        item.badge = item.desc_estado_ot
        if (item.idest_orden_trabajo === ORDEN_TRABAJO.estados.pendiente) {
          if (item.tiene_parte_pendiente) {
            item.badgeColor = ORDEN_TRABAJO.colores.asignada
          } else {
            item.badgeColor = ORDEN_TRABAJO.colores.pendiente
          }
        } else {
          item.badgeColor = ORDEN_TRABAJO.colores.finalizada
        }
        if (item.avisos) {
          item.alerts = [{
            value: this.$options.filters.linebreaksBr(item.avisos),
            type: 'warning',
            options: {
              persistent: true,
            },
          }]
        } else {
          item.alerts = []
        }
        if (item.ffijada) {
          item.alerts.push({
            value: `La orden de trabajo se encuentra fijada para el ${this.$options.filters.shortDateTime(item.ffijada)}`,
            type: 'warning',
          })
        }
        if (item.cant_partes_pendientes > 1) {
          item.alerts.push({ value: 'OT con más de un parte de trabajo pendiente', type: 'warning' })
        }
        item.moreInfo = this.buildMoreInfo(this.item.metadata)
        return item
      } else {
        return {}
      }
    },
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioIdalmacen: get('usuario/idalmacen'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      await this.loadItem()
      this.title = this.item.dataset.orden_trabajo_serie_numero
      const puedeAsignar = (
        this.item.dataset.idest_orden_trabajo === ORDEN_TRABAJO.estados.pendiente &&
        this.item.dataset.cant_partes_pendientes === 0
      )
      this.toolbarOptions.asignarAMi.visible = puedeAsignar && this.hasViewPerm(this.permissions.ordenes.asignarAMi)
      this.toolbarOptions.asignarAOtros.visible = puedeAsignar && this.hasViewPerm(this.permissions.ordenes.asignarAOtros)
      if (puedeAsignar && this.hasViewPerm(this.permissions.ordenes.asignarAOtros) && this.item.dataset.idtecnico_previsto){
        this.toolbarOptions.asignarAPrevisto.visible = true
        this.toolbarOptions.asignarAPrevisto.title = `Asignar a ${this.item.dataset.tecnico_previsto_nombre}`
      }
      this.parteTrabajoOffline = await Data.selectParteDeOrdenTrabajo(this, this.routeParams.idorden_trabajo)
      this.toolbarOptions.irParte.visible = !!this.parteTrabajoOffline
      if (this.item.dataset.avisar_antes_ir) {
        this.$alert.showSnackbarInfo(`Avisar antes de ir.`)
      }
      this.toolbarOptions.map.visible = !!this.item.dataset.latitud
      this.toolbarOptions.geolocalizar.visible = this.hasEditPerm(this.permissions.sistema.id)
      await this.loadDetailTotals()
    },
    async loadItem () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectOrdenTrabajo(this, this.routeParams.idorden_trabajo)
        if (dataset) {
          this.$set(this.item, 'dataset', dataset)
          this.$set(this.item, 'metadata', metadata)
          this.toolbarOptions.irSistema.visible = (
            !!this.item.dataset.idsistema && this.hasViewPerm(this.permissions.sistema.id)
          )
        } else {
          this.itemNotFound()
        }
      } finally {
        this.loadingData = false
      }
    },
    async loadDetailTotals () {
      this.initDetails()
      const resp = await Data.selectDetails(
        this,
        this.routeParams.idorden_trabajo,
        !!this.usuarioIdtecnico,
        !!this.usuarioIdcomercial,
        !!this.usuarioIdvigilante
      )
      // partes
      const detailPartesTrabajo = this.addDetail(
        'parte', 'Partes de trabajo', 'Partes de trabajo de la orden', 'parte'
      )
      detailPartesTrabajo.badge = resp.data.selectParteTrabajo.result.dataset[0].count || 0
      // contactos
      if (this.hasViewPerm(this.permissions.sistemaTelefono.id)) {
        const detailContactos = this.addDetail(
          'contactos', 'Personas de contacto', 'Personas de contacto del sistema', 'personaContacto'
        )
        const [ datasetPersonasContacto ] = await this.$online.sistemaTelefono.selectContactosDelSistema(this.item.dataset.idsistema, this.item.dataset.idcliente)
        detailContactos.badge = datasetPersonasContacto.length
      }
      // material instalado
      const detailMaterialInstalar = this.addDetail(
        'materialInstalar', 'Material a instalar/facturar', 'Material a instalar y/o servicios a facturar', 'materialInstalar'
      )
      let countMaterialInstalar = 0
      let totalsMaterialInstalar = []
      for (const rowMaterialInstalar of resp.data.selectLordenTrabajoSubsis.result.dataset) {
        countMaterialInstalar += rowMaterialInstalar.unidades
        totalsMaterialInstalar.push(`${rowMaterialInstalar.subsis_descripcion} (${filters.decimal(rowMaterialInstalar.unidades)})`)
      }
      detailMaterialInstalar.badge = filters.decimal(countMaterialInstalar) || 0
      detailMaterialInstalar.totals = totalsMaterialInstalar.join(', ')
      // material afectado
      const detailMaterialAfectado = this.addDetail(
        'materialAfectado', 'Material afectado', 'Afectado a revisiones o averías', 'materialAfectado'
      )
      let countMaterialAfectado = 0
      let totalsMaterialAfectado = []
      for (const rowMaterialAfectado of resp.data.selectOrdenTrabajoMatsistSubsis.result.dataset) {
        countMaterialAfectado += rowMaterialAfectado.material_sistema_unidades
        totalsMaterialAfectado.push(`${rowMaterialAfectado.subsis_descripcion} (${rowMaterialAfectado.material_sistema_unidades.toFixed()})`)
      }
      detailMaterialAfectado.badge = countMaterialAfectado || 0
      detailMaterialAfectado.totals = totalsMaterialAfectado.join(', ')
      // ficheros
      if (this.hasViewPerm(this.permissions.ordenTrabajoAdjunto.id)) {
        const detailFicheros = this.addDetail(
          'adjuntos', 'Adjuntos', 'Fotografías y ficheros adjuntos', 'attach'
        )
        detailFicheros.badge = resp.data.selectFicheroCount.result.dataset[0].count || 0
      }
      const filteredMaterialPendiente = _.filter(resp.data.selectCountLordenTrabajo.result.dataset, (record) => {
        const idlest = record?.idlest_orden_trabajo;
        return (
          idlest === LORDEN_TRABAJO.estados.pendiente_compra ||
          idlest === LORDEN_TRABAJO.estados.pendiente_recibir ||
          idlest === LORDEN_TRABAJO.estados.pendiente_gestion
        );
      });
      // Avisos
      if (filteredMaterialPendiente.length > 0) {
        this.$alert.showSnackbarInfo('La orden contiene material pendiente de compra y/o recepción')
        this.formattedItem.materialPendiente = true
      }
    },
    clickDetail (data) {
      if (data.detail.name === 'adjuntos') {
        this.$appRouter.push({
          name: 'ficheros__ordenes-fichero-list',
          params: {
            idtabla: TABLA.orden_trabajo.idtabla,
            id: this.routeParams.idorden_trabajo,
          },
        })
      } else if (data.detail.name === 'contactos') {
        this.$appRouter.push({
          name: 'telefonos__sistemas-cliente-telefono-list',
          params: {
            idsistema: this.item.dataset.idsistema,
          },
        })
      } else if (data.detail.name === 'materialInstalar') {
        this.$appRouter.push({
          name: 'ordenes__orden_trabajo_subsis-list',
          params: {
            idorden_trabajo: this.routeParams.idorden_trabajo,
          },
        })
      } else if (data.detail.name === 'materialAfectado') {
        this.$appRouter.push({
          name: 'ordenes__orden_trabajo_material_afectado-subsis-list',
          params: {
            idorden_trabajo: this.routeParams.idorden_trabajo,
          },
        })
      } else if (data.detail.name === 'parte') {
        this.$appRouter.push({
          name: 'ordenes__parte-trabajo-list',
          params: {
            idordenTrabajo: this.routeParams.idorden_trabajo,
          }
        })
      }
    },
    async clickToolbarOption (option) {
      if (option === this.toolbarOptions.asignarAMi) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignarte la orden de trabajo Nº${this.item.dataset.orden_trabajo_serie_numero} de "${this.item.dataset.cliente_nombre}"?`
        )
        if (res) {
          const parteAsignado = await this.$online.ordenTrabajo.asignar(this.routeParams.idorden_trabajo, this.usuarioIdtecnico)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.ordenTrabajo, this.routeParams.idorden_trabajo)
          const resDescargarParte = await this.$alert.showConfirm(
            `Se ha creado el parte de trabajo Nº${parteAsignado.idparte_trabajo} ¿Deseas descargarlo ahora?`
          )
          if (resDescargarParte) {
            await downloadParteTrabajo(this, parteAsignado.idparte_trabajo, this.usuarioIdalmacen, this.usuarioIdtecnico)
            this.$appRouter.push({
              name: 'offline__parte-trabajo-view',
              params: {
                idparte_trabajo: parteAsignado.idparte_trabajo,
              },
            })
          } else {
            this.$alert.showSnackbarSuccess(`Se ha creado el parte de trabajo Nº${parteAsignado.idparte_trabajo}`)
            this.$appRouter.go(-1)
          }
        }
      } else if (option === this.toolbarOptions.irSistema) {
        this.$appRouter.push({
          name: 'sistemas__sistema-view',
          params: {
            idsistema: this.item.dataset.idsistema,
          },
        })
      } else if (option === this.toolbarOptions.asignarAOtros) {
        this.$appRouter.push({
          name: 'ordenes__orden-trabajo-asignar',
          params: {
            idorden_trabajo: this.routeParams.idorden_trabajo,
          },
        })
      } else if (option === this.toolbarOptions.asignarAPrevisto) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignarte la orden de trabajo Nº${this.item.dataset.orden_trabajo_serie_numero} de "${this.item.dataset.cliente_nombre}" al técnico "${this.item.dataset.tecnico_previsto_nombre}"?`
        )
        if (res) {
          const parteAsignado = await this.$online.ordenTrabajo.asignar(this.routeParams.idorden_trabajo, this.item.dataset.idtecnico_previsto)
          await this.$dirty.modified(this.$dirty.ENTITIES.remote.ordenTrabajo, this.routeParams.idorden_trabajo)
          this.$alert.showSnackbarSuccess(`Se ha creado el parte de trabajo Nº${parteAsignado.idparte_trabajo}`)
          this.$appRouter.go(-1)
        }
      } else if (option === this.toolbarOptions.irParte) {
        // voy al parte si lo tengo descargado
        if (this.parteTrabajoOffline) {
          this.$appRouter.push({
            name: 'offline__parte-trabajo-view',
            params: {
              idparte_trabajo: this.parteTrabajoOffline.parte_trabajo.idparte_trabajo,
            },
          })
        }
      } else if (option === this.toolbarOptions.map) {
        window.open(getLatLongURL(this.item.dataset.latitud, this.item.dataset.longitud), '_blank')
      } else if (option === this.toolbarOptions.geolocalizar) {
        this.geolocalizar()
      }
    },
    async geolocalizar () {
      const geolocation = await captureGeolocation(this)
      if (!!geolocation?.lat && geolocation?.lng) {
        const res = await this.$alert.showConfirm(
          `¿Deseas asignar la geolocalización actual (${geolocation.lat}, ${geolocation.lng})
          a la dirección del sistema "${this.item.dataset.sistema_direccion}"?`)
        if (res) {
          await this.$online.clienteDireccion.geolocalizar(
            this,
            this.item.dataset.idcliente_direccion,
            geolocation.lat,
            geolocation.lng
          )
          await this.loadItem()
        }
      }
    },
  },
}
</script>
