<template>
  <div>
    <v-chip
      v-if="ffijada"
      class="ml-1 mt-1"
      small
      color="red"
      dark
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.calendar }}
      </v-icon>
      Fijada {{ ffijada|shortDateTime }}
    </v-chip>
    <v-chip
      v-if="avisarAntesIr"
      class="ml-1 mt-1"
      small
      color="warning"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.phone }}
      </v-icon>
      Avisar antes de ir
    </v-chip>
    <v-chip
      v-if="!manoDeObraFacturable"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.manoDeObra }}
      </v-icon>
      Mano de obra NO facturable
    </v-chip>
    <v-chip
      v-if="idtfacturacionKilometros === TFACTURACION_KILOMETROS.ids.noFacturable"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.desplazamiento }}
      </v-icon>
      Desplazamiento NO facturable
    </v-chip>
    <v-chip
      v-if="tmotivoBloqueoDescripcion"
      class="ml-1 mt-1"
      small
      color="error"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.motivoBloqueo }}
      </v-icon>
      {{ tmotivoBloqueoDescripcion }}
    </v-chip>
    <v-chip
      v-if="materialPendiente"
      class="ml-1 mt-1"
      small
      color="info"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.materialInstalar }}
      </v-icon>
      Material pendiente
    </v-chip>
    <v-chip
      v-if="cantAccionesTotal > 0"
      class="ml-1 mt-1"
      small
      color="success"
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.accionMaterial }}
      </v-icon>
      {{ cantAccionesRealizadas }} de {{ cantAccionesTotal }} acciones realizadas
    </v-chip>
    <v-chip
      v-if="pendienteDevolucion"
      class="ml-1 mt-1"
      small
      color="info"
      label
    >
      Devolución
    </v-chip>
    <v-chip
      v-if="tecnicoPrevisto"
      class="ml-1 mt-1"
      small
      color="info"
      dark
      label
    >
      <v-icon
        left
        small
      >
        {{ $vuetify.icons.values.user }}
      </v-icon>
      {{ tecnicoPrevisto }}
    </v-chip>
  </div>
</template>

<script>
import { TFACTURACION_KILOMETROS } from '@/utils/consts'

export default {
  props: {
    ffijada: {
      type: Date,
      default: function () { return new Date() },
    },
    avisarAntesIr: {
      type: Boolean,
    },
    manoDeObraFacturable: {
      type: Boolean,
    },
    idtfacturacionKilometros: {
      type: Number,
      default: 0,
    },
    cantAccionesRealizadas: {
      type: Number,
      default: 0,
    },
    cantAccionesTotal: {
      type: Number,
      default: 0,
    },
    tmotivoBloqueoDescripcion: {
      type: String,
      default: '',
    },
    pendienteDevolucion: {
      type: Boolean
    },
    tecnicoPrevisto: {
      type: String,
      default: '',
    },
    materialPendiente: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      TFACTURACION_KILOMETROS,
    }
  },
}
</script>
